<template>
  <div>
    <el-dialog fullscreen v-bind='$attrs' title='商家品牌信息' v-on='$listeners' @open='onOpen' :before-close='beforeClose'
               width='880px' center>
      <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
        <el-form-item label='店铺名称'>
          <el-input placeholder='店铺名称(支持模糊搜索)' v-model='searchCondition.seller_name' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item label='公司名称'>
          <el-input placeholder='公司名称(支持模糊搜索)' v-model='searchCondition.customer_name' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item label='品牌'>
          <el-input placeholder='品牌(支持模糊搜索)' v-model='searchCondition.brand_name' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item label='联系人'>
          <el-input placeholder='联系人' v-model='searchCondition.contact_user' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item label='联系人方式'>
          <el-input placeholder='联系人方式' v-model='searchCondition.contact_number' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item label='微信' style='display: none'>
          <el-input placeholder='微信ID' v-model='searchCondition.openid' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
          <el-button icon='el-icon-tickets'
                     v-if='userPermissions.indexOf("live-product-merchant-brand-handover-log") > -1'
                     @click='handoverRecord'>品牌交接记录
          </el-button>
        </el-form-item>
      </el-form>
      <div class='default-table'>
        <el-table :data='dataList' highlight-current-row border>
          <el-table-column type='index' label='序号' align='center' min-width='100'></el-table-column>
          <el-table-column align='center' label='店铺名称' min-width='100' prop='seller_name'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='公司名称' min-width='100' prop='customer_name'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='公司品牌' min-width='100' prop='brand_name'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='评分' min-width='100' prop='score'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='公司简介' min-width='100' prop='intro'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='联系人' min-width='100' prop='contact_user'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='联系方式' min-width='100' prop='contact_number'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='品牌所属人ID' min-width='100' prop='sysuid'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='邮寄地址' min-width='100' prop='delivery_info'
                           show-overflow-tooltip></el-table-column>
          <el-table-column label='录入时间' prop='created_at' width='120' align='center' sortable='custom'>
            <template slot-scope='{ row }'>
              <span>{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label='修改时间' prop='updated_at' width='140' align='center' sortable='custom'>
          </el-table-column>
          <el-table-column label='操作' prop='updated_at' width='160' align='center'>
            <template slot-scope='{row}'>
              <el-link type='danger' icon='el-icon-close' @click='handleDelete(row)'>移除</el-link>
              <el-link style="margin-left: 10px" icon='el-icon-folder-checked' @click='handleHandover(row)'
                       v-if='userPermissions.indexOf("live-product-merchant-brand-handover") > -1' type="warning">
                交接
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <pagination :background='true' :total.sync='total' :page.sync='pageData.current_page'
                    :limit.sync='pageData.page_size' @pagination='getList' />
      </div>
    </el-dialog>
    <el-dialog
        width="30%"
        title="品牌交接"
        :visible.sync="handoverVisible"
        append-to-body>
      <div class="warning">
        <i class="el-alert__icon el-icon-warning is-big"></i>
        <span>
          注意：品牌交接成功后，品牌下关联的 <b style="font-size: 16px">所有产品</b> 也一并交接给对方。请确认后，再操作！！
        </span>
      </div>
      <div>
        <br/>
        <el-input placeholder="请输入对接人userID" v-model="formData.sysuid">
          <template slot="prepend">对接人userID</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmHandover">确 定</el-button>
        <el-button @click="handoverVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
        width="40%"
        title="品牌交接记录"
        :visible.sync="handoverRecordVisible"
        append-to-body>
      <el-table :data='recordList' highlight-current-row border>
        <el-table-column type='index' label='序号' align='center' min-width='100'></el-table-column>
        <el-table-column label='品牌' align='center' prop="brand_name" min-width='100'></el-table-column>
        <el-table-column label='交接内容' align='center' prop="brand_name" min-width='200'>
          <template slot-scope="{row}">
            由 {{ row._fromSysuid }} 交接给 <span style="color: #800440"> {{ row._toSysuid }}</span>
          </template>
        </el-table-column>

      </el-table>
      <pagination :background='true' :total.sync='recordData.total' :page.sync='recordData.current_page'
                  :limit.sync='recordData.page_size' @pagination='getRecordList'/>
      <!--      handoverRecord-->
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BusinessBrand',
  components: {},
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      dataList: [],
      pageData: { page_size: 20, current_page: 1 },
      total: 0,
      searchCondition: {},
      handoverVisible: false,
      formData: {
        id: null,
        sysuid: null
      },
      handoverRecordVisible: false,
      recordList: [],
      recordData: { page_size: 20, current_page: 1, total: 0 }

    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    beforeClose() {
      this.$emit('close')
    },
    //处理搜索条件
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.openid) {
        cond.openid = this.searchCondition.openid
      }
      if (this.searchCondition.seller_name) {
        cond.seller_name = this.searchCondition.seller_name
      }
      if (this.searchCondition.contact_user) {
        cond.contact_user = this.searchCondition.contact_user
      }
      if (this.searchCondition.contact_number) {
        cond.contact_number = this.searchCondition.contact_number
      }
      if (this.searchCondition.brand_name) {
        cond.brand_name = this.searchCondition.brand_name
      }
      if (this.searchCondition.customer_name) {
        cond.customer_name = this.searchCondition.customer_name
      }
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    //获取表单数据
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getMerchantList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleDelete(row) {
      this.$confirm(`该操作将删除商家品牌【${row.brand_name}】，是否继续？移除后若想恢复，请联系管理员！！！`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.delInfo(row.id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    async delInfo(id) {
      if (id) {
        await this.$api.deleteMerchantInfo(id)
        this.msgSuccess('已移除！！！')
        await this.getList()
      }
    },
    handleHandover(row) {
      this.formData.id = row.id
      this.handoverVisible = true
    },
    confirmHandover() {
      this.$confirm(`品牌交接成功后，品牌下关联的 所有产品 也一并交接给对方。请确认！！`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.handoverBrand()
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    async handoverBrand() {
      await this.$api.productHandoverBrandData(this.formData)
      this.msgSuccess('交接成功！')
      this.handoverVisible = false
      this.formData = {
        id: null,
        sysuid: null
      }
      await this.getList()
    },
    handoverRecord() {
      this.getRecordList()
      this.handoverRecordVisible = true

    },

    async getRecordList() {
      let { list, pages } = await this.$api.getProductHandoverBrand(this.recordData)
      this.recordList = list || []
      this.recordData.current_page = pages.current_page || 1
      this.recordData.page_size = pages.page_size || 1
      this.recordData.total = pages.total
    }

  }
}
</script>

<style scoped>

.warning {
  padding: 10px;
  border-radius: 4px;
  color: #f56c6c;
  background-color: #fdf6ec;
  line-height: 20px;
  display: flex;
}

.warning > i {
  display: inline-block;
  width: 60px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
}

</style>
