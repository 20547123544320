<template>
  <div>
    <!-- 审核详情 -->
    <el-drawer
      :visible.sync='open'
      z-index='99' :show-close='true' :wrapperClosable='false' :destroy-on-close='true' title='审核-产品库'
      size='1000px' v-bind='$attrs'
      v-on='$listeners'
      @open='onOpen'
      @close='onClose'>
      <el-card class='form-card'>
        <el-form ref='elForm' size='mini' :model='formData' :rules='rules' label-width='140px'>
          <el-row :gutter='15'>
            <el-col :span='12'>
              <el-form-item label='可播平台' prop='platform_code'>
                <PlatformSelect @saved='handleSelectPlatform' :range-all='true' v-model='formData.platform_code'
                                placeholder='请选择可播平台' :default-code='formData.platform_code' />
              </el-form-item>
              <el-form-item label='商品名' prop='title'>
                <el-input v-model='formData.title' placeholder='输入商品名' maxlength='100' show-word-limit clearable />
              </el-form-item>
              <el-form-item label='规格' prop='spec_name'>
                <el-input v-model='formData.spec_name' placeholder='规格' maxlength='100' show-word-limit clearable />
              </el-form-item>
              <el-form-item label='季节性' prop='used_season'>
                <el-radio-group v-model="formData.used_season">
                  <el-radio label="春"></el-radio>
                  <el-radio label="夏"></el-radio>
                  <el-radio label="秋"></el-radio>
                  <el-radio label="冬"></el-radio>
                </el-radio-group>
<!--                <el-input v-model='formData.used_season' clearable maxlength='100' placeholder='季节性' show-word-limit />-->
              </el-form-item>
              <el-form-item label='色号/香型' prop='spec_color'>
                <el-input v-model='formData.spec_color' clearable maxlength='100' placeholder='色号/香型' show-word-limit />
              </el-form-item>
              <el-form-item label='类目' required>
                <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange'
                                  :selected-list.sync='selectedList' />
              </el-form-item>
              <el-form-item label='合作方式' required>
                <CategoryCascader :identify='`mode`' @cascaderChange='cascaderModeChange'
                                  :selected-list.sync='selectedModeList' />
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='' label-width='10px'>
                <product-image-upload :img-url='formData.cover_url' @uploaded='getCover' />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter='15'>
            <el-col :span='12'>
              <el-form-item label='日常价(元)' prop='price'>
                <el-input v-model='formData.price' placeholder='日常价' maxlength='200' show-word-limit clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='直播价(元)' prop='lb_price'>
                <el-input v-model='formData.lb_price' placeholder='直播价' maxlength='200' show-word-limit clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter='15'>
            <el-col :span='12'>
              <el-form-item label='线上佣金(%)' prop='online_cmn'>
                <template slot='label'>
                  <span>线上佣金(<b class='rate-txt'>%</b>)</span>
                </template>
                <el-input v-model='formData.online_cmn' placeholder='线上佣金，比如：10%'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='线下佣金(%)' prop='offline_cmn'>
                <template slot='label'>
                  <span>线下佣金(<b class='rate-txt'>%</b>)</span>
                </template>
                <el-input v-model='formData.offline_cmn' placeholder='线下佣金，比如：10%'
                          clearable>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label='坑位费' prop='ad_space_fee'>
            <el-input v-model='formData.ad_space_fee' placeholder='坑位费' maxlength='100' show-word-limit clearable />
          </el-form-item>
          <el-form-item label='产品产地' prop='origin_place'>
            <el-input v-model='formData.origin_place' clearable maxlength='100' placeholder='产品产地' show-word-limit />
          </el-form-item>
          <el-form-item label='赠品' prop='gift'>
            <el-input v-model='formData.gift' placeholder='赠品' maxlength='200' show-word-limit clearable />
          </el-form-item>
          <el-form-item label='产品链接'>
            <el-input v-model='formData.product_url' placeholder='产品链接' maxlength='300' show-word-limit clearable />
          </el-form-item>
          <el-form-item label='有效期'>
            <el-input v-model='formData.period_validity' placeholder='产品有效期' maxlength='50' show-word-limit clearable />
          </el-form-item>
          <el-form-item label='是否签有独家' prop='exclusive' required>
            <el-radio-group v-model='formData.exclusive'>
              <el-radio :label='1'>是</el-radio>
              <el-radio :label='2'>否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label='独家机制' v-show='formData.exclusive==1'>
            <el-input v-model='formData.other_activity' placeholder='独家机制' maxlength='500' show-word-limit
                      clearable />
          </el-form-item>
          <el-form-item label='是否全网统一机制' prop='unify'>
            <el-radio-group v-model='formData.unify'>
              <el-radio :label='1'>是</el-radio>
              <el-radio :label='2'>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show='formData.unify===2' label='特殊机制' prop='other_activity'>
            <el-input v-model='formData.special_remark' placeholder='特殊机制' maxlength='500' show-word-limit
                      clearable />
          </el-form-item>
          <el-form-item label='是否可签保价1个月' prop='support_value'>
            <el-radio-group v-model='formData.support_value'>
              <el-radio :label='1'>是</el-radio>
              <el-radio :label='2'>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='是否特殊化妆品' prop='is_special' required>
            <el-radio-group v-model='formData.is_special'>
              <el-radio :label="'Y'">是</el-radio>
              <el-radio :label="'N'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='是否医疗器械' prop='is_medical' required>
            <el-radio-group v-model='formData.is_medical'>
              <el-radio :label="'Y'">是</el-radio>
              <el-radio :label="'N'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='是否秒杀品' prop='is_sec_kill' required>
            <el-radio-group v-model='formData.is_sec_kill'>
              <el-radio :label="'Y'">是</el-radio>
              <el-radio :label="'N'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label='历史达播销量'>
            <el-input v-model='formData.sales_volume' clearable maxlength='50' placeholder='如李佳琦7淘宝/500w/11月1日'
                      show-word-limit />
          </el-form-item>
          <el-form-item label='亮点卖点'>
            <ApeEditor :editorHeight='300' :init-html='formData.light_spot'
                       :inline='true'
                       :showMenu='false' @handleTinymceInput='handleTinymceInput'
                       :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify | bullist numlist' +
          ' | outdent indent blockquote | removeformat anchor hr |' +
          ' code preview fullscreen'"
            ></ApeEditor>
          </el-form-item>
          <el-form-item label='备注信息' prop='remark'>
            <el-input v-model='formData.remark' placeholder='备注信息' maxlength='255' show-word-limit clearable />
          </el-form-item>
          <el-form-item label='店铺名称' prop='company_name'>
            <el-input v-model='formData.company_name' placeholder='店铺名称' maxlength='100' show-word-limit
                      clearable />
          </el-form-item>
          <el-form-item label='店铺评分'>
            <el-input v-model='formData.company_score' placeholder='店铺评分' clearable />
          </el-form-item>
          <el-form-item label='品牌背书' prop='intro'>
            <el-input v-model='formData.intro' placeholder='品牌背书' maxlength='400' show-word-limit
                      :autosize='{ minRows: 8, maxRows: 20}'
                      type='textarea' clearable />
          </el-form-item>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='店铺注册公司' prop='customer_name'>
                <el-input v-model='formData.customer_name' disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label=''>
                <el-select v-model='formData.customer_name' :remote-method='remoteMethod'
                           allow-create clearable default-first-option filterable placeholder='请选择公司或输入公司名'
                           remote
                           reserve-keyword
                           style='width: 100%'
                           @change="setSelectValue('customer')">
                  <el-option
                    v-for='item in companyList'
                    :key='item.id+item.name'
                    :label='item.name'
                    :value='item.name'>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='品牌' prop='brand_name' required>
                <el-input v-model='formData.brand_name' disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label=''>
                <el-select v-model='formData.brand_name' :remote-method='barndRemoteMethod'
                           allow-create clearable default-first-option filterable
                           placeholder='请选择品牌或输入品牌名称'
                           remote
                           reserve-keyword style='width: 100%'
                           @change="setSelectValue('brand')">
                  <el-option
                    v-for='item in brandList'
                    :key='item.id'
                    :label='item.name'
                    :value='item.name'>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='12'>
              <el-form-item label='联系人' prop='contact_user'>
                <el-input v-model='formData.contact_user'></el-input>
              </el-form-item>
            </el-col>
            <el-col :span='12'>
              <el-form-item label='联系方式' prop='contact_info'>
                <el-input v-model='formData.contact_info' style='width: 200px'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label='物流信息'>
            <el-input v-model='formData.delivery_info' clearable maxlength='255'
                      :rows='2' type='textarea' show-word-limit />
          </el-form-item>
          <el-form-item label='负责人' prop='leader_id'>
            <el-input v-if='formData.id&&!editLeader' v-model='formData.leader_name' placeholder='负责人'
                      disabled style='width: 200px'>
              <template slot='append'>
                <el-button icon='el-icon-edit' @click='editLeader=true'></el-button>
              </template>
            </el-input>
            <UserSelect :leader-id.sync='formData.leader_id' :keyword='formData.leader_name'
                        @selected='handleUserSelect' v-else />
          </el-form-item>

          <!--            <el-row>-->
          <!--              <el-col :span="12">-->
          <!--                <el-form-item label='联系人'>-->
          <!--                  <el-input v-model='formData.contact.contact_user'></el-input>-->
          <!--                </el-form-item>-->
          <!--              </el-col>-->
          <!--              <el-col :span="12">-->
          <!--                <el-form-item label='联系方式'>-->
          <!--                  <el-input v-model='formData.contact.contact_info'></el-input>-->
          <!--                </el-form-item>-->
          <!--              </el-col>-->
          <!--            </el-row>-->

          <!--            <el-form-item label='物流信息'>-->
          <!--              <el-input v-model='formData.contact.delivery_info' placeholder='物流信息' maxlength='255' show-word-limit-->
          <!--                        clearable/>-->
          <!--            </el-form-item>-->
          <!--            <el-button type="text" style="float: right" @click="onFillIn">一键填入</el-button>-->

          <br>
          <el-form-item>
            <div style='text-align: center'>
              <!--                        <div style="text-align: center">-->
              <el-button type='primary' size='medium' @click='productUpdate'
                         v-if="userPermissions.indexOf('live-product-update')>-1&&formData.status == 1"
                         :disabled='loadingCommit' :loading='loadingCommit'>
                保存信息
              </el-button>
              <el-button type='primary' size='medium' @click='productCheckAgree' :disabled='loadingCommit'
                         :loading='loadingCommit'
                         v-if="userPermissions.indexOf('live-product-agree')>-1&&formData.status == 1">通过并保存到库
              </el-button>
              <el-button type='primary' size='medium' @click='refuseDialogVisible = true' :disabled='loadingCommit'
                         :loading='loadingCommit'
                         v-if="userPermissions.indexOf('live-product-refuse')>-1&&formData.status == 1">审核不通过
              </el-button>
              <el-button type='warning' icon='el-icon-refresh-left' size='medium'
                         v-if="userPermissions.indexOf('live-product-retrial')>-1&&formData.status == 2"
                         style='float: right' @click='handleRetrial'>退回
              </el-button>
            </div>

          </el-form-item>
        </el-form>
      </el-card>
    </el-drawer>
    <!--         拒绝说明-->
    <el-dialog title='拒绝理由' width='30%' :visible.sync='refuseDialogVisible'>
      <el-form :model='refuseForm' :rules='refuseFormRules' ref='refuseFormRef' label-width='100px'
               class='demo-ruleForm'>
        <el-form-item label='理由' prop='refuse_reason'>
          <el-input v-model='refuseForm.refuse_reason'></el-input>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
                <el-button @click='refuseDialogVisible = false'>取 消</el-button>
                <el-button type='primary' @click='productCheckRefuse'>确 定</el-button>
            </span>
    </el-dialog>
    <!--    当前商品信息展示-->
<!--    <FormCompareView ref='refFormCompareView' :productId='productId'></FormCompareView>-->
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import ApeUploader from '@/components/ApeUploader'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ProductImageUpload from '@/pages/lb/product/ProductImageUpload'
import UserSelect from '@/pages/lb/components/UserSelect'
import { mapGetters } from 'vuex'
import ApeEditor from '@/components/ApeEditor'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import FormCompareView from '@/pages/lb/product/audit/cosmetic/components/FormCompareView'

export default {
  name: 'AuditShow',
  components: {
    FormCompareView,
    UserSelect,
    ProductImageUpload,
    CategoryCascader,
    ApeUploader,
    PlatformSelect,
    ArtistSearch,
    ApeEditor
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      open: false,
      id: null,
      productId: null,
      loadingStatus: false,
      loadingCommit: false,
      // 已上传图片列表
      uploadFileList: [],
      allowTypeList: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'],
      cascaderProps: {
        label: 'display_name',
        value: 'id'
      },
      // 已选中的分类列表，用于cascader组件选中效果
      selectedList: [],
      selectedModeList: [],
      disabledEditPic: false,
      editLeader: false,
      formData: {
        title: '',
        spec_name: '',
        cover: null,
        cover_url: '',
        product_url: '',
        category_id: null,
        category_2_id: null,
        category_ids: null,
        mode: 1,
        price: 0,
        lb_price: 0,
        ad_space_fee: 0,
        online_cmn: 0,
        offline_cmn: 0,
        gift: '',
        other_activity: '',
        remark: '',
        company_name: '',
        leader_id: null,
        leader_name: null,
        company_score: 0,
        customer_id: null,
        mini_customer_name: null,
        mini_brand_name: null,
        customer_name: null,
        brand_name: null,
        merchant: {
          delivery_info: null,
          customer_name: null,
          brand_name: null,
          contact_user: null,
          contact_number: null
        },
        delivery_info: null,
        contact_user: null,
        contact_info: null,
        origin_place: null,
        is_special: false,
        unify: null,
        is_medical: null,
        is_sec_kill: null,
        platform_code: null,
        special_remark: '',
        period_validity: '',
        spec_color: ''
      },
      lbTimeRange: [],
      rules: {
        title: { required: true, message: '商品名称必填', trigger: 'blur' },
        spec_name: { required: true, message: '规格必填', trigger: 'blur' },
        price: { required: true, message: '日常价必填', trigger: 'blur' },
        lb_price: { required: true, message: '直播价格必填', trigger: 'blur' },
        online_cmn: { required: true, message: '线上佣金必填', trigger: 'change' },
        offline_cmn: { required: true, message: '线下佣金必填', trigger: 'blur' },
        // gift: { required: true, message: '赠品必填', trigger: 'blur' },
        // remark: { required: true, message: '备注信息', trigger: 'blur' },
        company_name: { required: true, message: '店铺名称必填', trigger: 'blur' },
        leader_id: { required: true, message: '请选择负责人', trigger: 'change' },
        brand_name: { required: true, message: '品牌信息必填', trigger: 'change' },
        contact_info: { required: true, message: '联系人方式必填', trigger: 'change' },
        is_special: { required: true, message: '请选择是否特殊化妆品', trigger: 'change' },
        is_medical: { required: true, message: '请选择是否医疗器械', trigger: 'change' },
        is_sec_kill: { required: true, message: '请选择是否是秒杀品', trigger: 'change' },
        exclusive: { required: true, message: '请选择是否签有独家', trigger: 'change' }
      },

      refuseDialogVisible: false, //拒绝理由输入框
      refuseForm: { refuse_reason: '' },
      refuseFormRules: {
        refuse_reason: [{ required: true, message: '请输入拒绝理由' }]
      },
      companyList: [],
      brandList: [],
      companyOption: [],
      brandOptions: [],
      optionData: {
        customer_name: null,
        brand_name: null
      }
    }
  },
  methods: {
    show(currId) {
      this.id = currId
      this.open = true
    },
    // blurFun(type) {
    //   switch (type) {
    //     case 'brand':
    //   console.log( this.mini_customer_name)
    //       break
    //   }
    // },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.companyList = this.companyOption.filter(item => {
            return item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    barndRemoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.brandList = this.brandOptions.filter(item => {
            return item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    // onFillIn() {
    //   let { customer_name, brand_name, contact_user, contact_number, delivery_info } = this.formData.merchant
    //   let data = {
    //     mini_customer_name: customer_name,
    //     mini_brand_name: brand_name,
    //     // contact_user: contact_user,
    //     // contact_info: contact_number,
    //     // delivery_info: delivery_info
    //   }
    //   this.formData.contact = { ...this.formData, ...data }
    // },
    setSelectValue(type) {
      let option = null
      switch (type) {
        case 'customer':

          option = this.companyOption.find(item => item.name === this.formData.customer_name)
          if (option) {
            this.formData.customer_id = option.id
            this.formData.customer_name = option.name
          } else {
            this.formData.customer_id = 0
            // this.formData.customer_name = this.formData.customer_name
          }
          break
        case 'brand':
          option = this.brandOptions.find(item => item.name === this.formData.brand_name)
          if (option) {
            this.formData.brand_id = option.id
            this.formData.brand_name = option.name
          } else {
            // this.formData.brand_name = this.formData.brand_name
            this.formData.brand_id = 0
          }
          break
      }
    },
    async getBrand() {
      let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
      this.brandOptions = data
    },
    // btnScore(val) {
    //   this.formData.company_score = Number(val)
    // },
    //编辑器
    handleTinymceInput(val) {
      this.formData.light_spot = val
    },
    onOpen() {
      this.loadingCommit = false
      this.disabledEditPic = true
      this.editLeader = false
      this.formData = {
        title: null,
        spec_name: null,
        spec_color: null,
        cover: null,
        cover_url: null,
        product_url: null,
        category_id: null,
        category_2_id: null,
        category_ids: null,
        mode: null,
        price: null,
        lb_price: null,
        ad_space_fee: null,
        online_cmn: null,
        offline_cmn: null,
        gift: null,
        other_activity: null,
        remark: null,
        company_name: null,
        leader_id: null,
        leader_name: null,
        customer_name: null,
        brand_name: null,
        mini_customer_name: null,
        mini_brand_name: null,
        merchant: {
          delivery_info: null,
          customer_name: null,
          brand_name: null,
          contact_user: null,
          contact_number: null
        },
        delivery_info: null,
        contact_user: null,
        contact_info: null,
        special_remark: null,
        period_validity: null
        // contact: {
        //   delivery_info: null,
        //   contact_user: null,
        //   contact_info: null
        // }
      }
      // this.optionData = {
      //   customer_name: null,
      //   brand_name: null
      // }
      // Object.assign(this.formData, this.defaultFormData)
      // 类目
      this.selectedList = this.formData.category_ids && this.formData.category_ids != null ? this.formData.category_ids : [this.formData.category_id]
      this.selectedModeList = this.formData.mode ? [this.formData.mode] : []
      //显示产品图片
      this.uploadFileList = this.formData.cover_url && this.formData.cover_url.indexOf('://') > -1 ? [{ url: this.formData.cover_url }] : []

      if (this.id) {
        this.getInfo()
      }
    },
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    getCover(val) {
      this.formData.cover = val.id || null
      this.formData.cover_url = val.full_path || null
      this.uploadFileList = [{ id: val.id, url: val.full_path }]
      this.$forceUpdate()
    },
    formatInfo(info) {
      if (info?.brand_name === '') {
        // info.mini_brand_name = info?.merchant?.brand_name || ''
        // info.mini_customer_name = info?.merchant?.customer_name || ''
        info.contact_user = info?.merchant?.contact_user || ''
        info.contact_info = info?.merchant?.contact_number || ''
        info.delivery_info = info?.merchant?.delivery_info || ''
        info.customer_name = info?.mini_customer_name || ''
        info.brand_name = info?.mini_brand_name || ''
      }
      this.formData = info

      if (this.formData?.customer_name && this.formData.customer_name === '') {
        this.formData.customer_name = this.formData.mini_customer_name
      }
      if (this.formData?.brand_name && this.formData.brand_name === '') {
        this.formData.brand_name = this.formData.mini_brand_name
      }
      // this.formData.company_score = Number(this.formData.company_score)


    },
    async getInfo() {
      let obj = {}
      obj.id = this.id
      let { info } = await this.$api.getProductInfoById(obj)
      this.formatInfo(info)

      this.selectedList = info.category_ids && info.category_ids != null ? info.category_ids : [info.category_id]
      this.selectedModeList = [info.mode]
      //显示产品图片
      this.uploadFileList = info.cover_url && info.cover_url.indexOf('://') > -1 ? [{ url: info.cover_url }] : []

    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.formData.category_id = null
      if (v.length) {
        this.formData.category_id = v[0]
        if (v.length === 2) {
          this.formData.category_2_id = v[1]
        }
        this.formData.category_ids = v
      }
    },
    cascaderModeChange(v) {
      this.formData.mode = null
      if (v.length) {
        this.formData.mode = v[(v.length - 1)]
      }
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.leader_id = item.value || ''
      this.formData.leader_name = item.label || ''
    },
    //信息更新
    productUpdate() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }
        if (!this.formData.category_id || !this.formData.mode) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }
        this.formData.gift = this.formData.gift ? this.formData.gift : ''

        if (!this.formData?.delivery_info) {
          this.formData.delivery_info = this?.merchant?.delivery_info || ''
        }

        // let {
        //   customer_name,
        //   customer_id,
        //   brand_name,
        //   brand_id,
        //   mini_brand_name,
        //   mini_customer_name,
        //   contact_user,
        //   contact_info,
        //   delivery_info
        // } = this.formData
        // console.log(customer_name, 'customer_name', mini_customer_name, 'mini_customer_name】', customer_id, 'customer_id', brand_name, '【brand_name', mini_brand_name, 'mini_brand_name】', brand_id, 'brand_id',
        //     contact_user, 'contact_user', contact_info, 'contact_info', delivery_info, 'delivery_info')
        this.loadingCommit = true
        const res = await this.$api.prodectCheckUpdate(this.formData)
        this.loadingCommit = false
        if (res) {
          this.$message.success('保存成功')
          await this.getInfo()
          this.close()
        }
      })
    },
    //审核通过
    productCheckAgree() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }
        this.loadingCommit = true
        const res = await this.$api.productCheckAgree(this.formData)
        if (res) {
          this.$message.success('审核通过')
        }
        setTimeout(() => {
          this.loadingCommit = false
        }, 1000)

        await this.getInfo()
        this.close()
      })
    },
    //审核拒绝
    productCheckRefuse() {
      this.$refs.refuseFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请输入拒绝理由')
        }
        this.formData.refuse_reason = this.refuseForm.refuse_reason
        this.loadingCommit = true
        const res = await this.$api.productCheckRefuse(this.formData)
        this.loadingCommit = false
        if (res) {
          this.refuseDialogVisible = false
          await this.getInfo()
          this.close()
        }
      })
    },

    async getOptionData() {
      //获取公司下拉
      this.companyOption = await this.$api.selectCustomList()
      //获取品牌下拉
      this.brandOptions = await this.$api.selectBrand()
    },
    handleSelectPlatform(val) {
      this.formData.platform_code = val ? val.code : []
      this.formData.platform_name = val ? val.name : []
    },
    // 退回重审操作
    handleRetrial() {

      // this.$refs['refFormCompareView'].show(this.formData)

      // this.productId = this.formData.product_id || null

      let msg = `确定要将【${this.formData.title}】退回重审，商品信息为同步当前（服饰）产品库信息？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        await this.$api.productCheckRetrial({ id: this.formData.id })
        // 发起网络请求
        this.formData.status = 1
        await this.$msgbox.alert('退回成功')
        this.open = false
      }).catch(() => {
        this.$notify.info('已取消操作')
      })
    }
  },
  mounted() {
    this.getOptionData()
  }
}
</script>

<style scoped>
</style>
